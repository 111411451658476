.content{
  padding: .64rem 1.9rem 1.64rem;
  background: #fff;
  .title{
    font-family: Anton;
    font-size: .64rem;
    font-weight: 400;
    line-height: 1;
    text-align: left;
    margin-bottom: .16rem;
  }
  .date{
    font-family: Avenir;
    font-size: .18rem;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: .48rem;
  }
  h2{
    font-family: Avenir;
    font-size: .24rem;
    font-weight: 900;
    line-height: 1.4;
    margin-bottom: .12rem;
  }
  h4{
    font-family: Avenir;
    font-size: .2rem;
    font-weight: 900;
    line-height: 1.3;
  }
  p{
    font-family: Avenir;
    font-size: .2rem;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: .32rem;

  }
  h3{
    font-family: Avenir;
    font-size: .2rem;
    font-weight: 900;
    line-height: 1.3;

  }

  &.mContent{
    padding: 100px 20px 40px;
    .title{
      font-size: 32px;
      margin-bottom: 16px;
    }
    .date{
      font-size: 16px;
    }
    h2,h3{
      font-size: 20px;
      margin-bottom: 12px;
    }
    p{
      font-size:14px;
      margin-bottom: 32px;
    }
    h4{
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

}