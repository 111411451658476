.applyform{
  background-color: #fff;
  .banner{
    padding: .75rem 0;
    background-size: cover;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.Agent{
      background: url(../../../assets/img/Agent_bg.png) no-repeat top center;
    }
    
    h1{
      font-family: Anton;
      font-size: .64rem;
      font-weight: 400;
      line-height: 1;
    }
    p{
      font-family: Avenir;
      font-size: .24rem;
      font-weight: 900;
      line-height: 1.2;
      margin: .17rem 0 .24rem;
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 2.4rem;
      // height: .72rem;
      padding: .24rem .64rem;
      background-color: #252525;
      border-radius: .16rem;
      box-shadow: .08rem .08rem 0px 0px #90FCF9;
      font-family: Anton;
      font-size: .24rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      color: #fff;
      text-decoration: none;

    }
  }
  .form{
    padding: .64rem 1.9rem 1.64rem;
    .content{
      padding: 0;
    }
    .desc{
      font-family: Avenir;
      font-size: .2rem;
      font-weight: 500;
      line-height: 1.3;
      text-align: center;
      margin-bottom: .64rem;
    }
    .form-item{
      display: flex;
      flex-direction: column;
      margin-bottom: .32rem;
      position: relative;
      label{
        font-family: Avenir;
        font-size: .2rem;
        font-weight: 900;
        line-height: 1.3;
        text-align: left;
        margin-bottom: .12rem;
      }
      input{
        padding: .16rem;
        gap: 0px;
        border-radius: .16rem;
        border: .02rem;
        border: .02rem solid #252525;
        font-family: Avenir;
        font-size: .2rem;
        font-weight: 900;
        line-height: 1.3;
        text-align: left;

      }
      .radioBox{
        display: flex;
        flex-direction: column;
        label{
          font-family: Avenir;
          font-size: .2rem;
          font-weight: 500;
          line-height: 1.3;
          text-align: left;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          padding: .17rem 0;

          input{
            margin-right: .16rem;
            width: .24rem;
            height: .24rem;
          }
        }
      }
      .tip{
        font-family: Avenir;
        font-size: .16rem;
        font-weight: 500;
        line-height: 1.4;
        text-align: left;
        margin-top: .12rem;
        img{
          height: 1.53rem;
          border-radius: 18px;
          margin-top: .12rem;
        }
      }
      .error{
        position: absolute;
        bottom: -0.24rem;
        left: 0;
        font-size: .12rem;
        color: red;
      }
    }
    .submit{
      box-shadow: 8px 8px 0px 0px #F4D250;
      background: #252525;
      width: 3.12rem;
      padding: .24rem .64rem;
      border-radius: .16rem;
      font-family: Anton;
      font-size: .24rem;
      font-weight: 400;
      line-height: 1;
      color: #fff;
    }
    .desctip{
      font-family: Avenir;
      font-size: .12rem;
      font-weight: 500;
      line-height: 1.4;
      text-align: left;
      margin-top: .24rem;
    }
  }
  &.mApplyform{
    padding-top: 72px;
    .banner{
      height: 286px;
      background-size: auto 100%;
      padding:  0 50px;
      h1{
        font-size: 34px;
      }
      p{
        font-size:16px;
        margin: 16px 0 42px;
      }
      .btn{
        width: auto;
        padding: 16px 31px;
        font-size: 18px;
        border-radius: 16px;
        box-shadow: 4px 4px 0px 0px #90FCF9;

      }
    }
    .form{
      padding: 40px 20px 60px;
      .desc{
        font-size: 14px;
        margin-bottom: 50px;
      }
      .content{
        .form-item{
          margin-bottom: 32px;
          label{
            font-size: 20px;
            margin-bottom: 12px;
          }
          .radioBox{
            label{
              font-size: 16px;
              input{
                width: 24px;
                height: 24px;
                margin-right: 16px;
              }
            }
          }
          input{
            border-radius: 16px;
            font-size: 20px;
            padding: 10px;
          }
          .tip{
            font-size: 12px;
            margin-top: 12px;
            img{
              height: 153px;
              margin-top: 12px;
            }
          }
        }
      }
      .submit{
        box-shadow: 4px 4px 0px 0px #F4D250;
        padding: 16px 48px 16px 48px;
        border-radius: 16px;
        font-size: 18px;
        display: block;
        margin: auto;
        width: auto;
      }
      .desctip{
        font-size: 12px;
        text-align: center;
        margin-top: 12px;
      }
    }
  }
}