.mHeader{
  width: 100vw;
  height: 72px;
  padding: 22px 20px;
  z-index: 10000;
  background: #252525;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 600ms cubic-bezier(.4,0,.2,1);
  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.burger--active{
    background: #F4D250;
    .mHeader-logo{
      opacity: 0;
      visibility: hidden;
    }
    .navBtn{

      .btn{
  
        .patty {
          transform: scale(0);
        }
        .top-bun {
          transform: translatex(0) rotate(-135deg);
          width: 28px;
        }
        .bottom-bun {
          transform: translatex(0) rotate(135deg);
          width: 28px;
        }
      }
    }
  }
  &-logo {
    height: 28px;
    transition: visibility 600ms cubic-bezier(.4,0,.2,1), opacity 400ms cubic-bezier(.4,0,.2,1);
    opacity: 1;
    visibility: visible;
    will-change: opacity, visibility;
    a{
      display: flex;
      align-items: center;
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
  .navBtn{

    .btn{
      width: 40px;
      height: 40px;
      border-radius: 12px;
      background: #F4D250;
      position: relative;
      z-index: 999;
      .top-bun,.patty,.bottom-bun {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 18px;
        border-radius: 12px;
        transition: transform 250ms cubic-bezier(.2,.6,.3,1), width 250ms cubic-bezier(.2,.6,.3,1);
        will-change: transform, width;
        height: 2px;
        background-color: #252525;
      }
      .top-bun{
        transform: translatey(-4px);
      }
      .bottom-bun{
        transform: translatey(4px);
      }
      
    }
  }
}


.header-menu {
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
  user-select: none;
  text-align: center;
  pointer-events: auto;
  z-index: 99;
  transition: visibility 600ms cubic-bezier(.4,0,.2,1), opacity 400ms cubic-bezier(.4,0,.2,1);
  &-bg{
    background-color: #F4D250;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &.header--menu-open{
    opacity: 1;
    visibility: visible;
  }

  &-nav{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: scroll;
    position: relative;
    justify-content: center;
    align-items: flex-start;
    padding-left: 40px;
    padding-top: 90px;
    .nav{
      text-align: left;
      .item{
        margin-bottom: 32px;
        a{
          font-family: Anton;
          font-size: 48px;
          font-weight: 400;
          line-height: 48px;
          text-align: left;
          text-transform: uppercase;
          text-decoration: none;
          color: #252525;
        }
      }
    }
    .apply{
      padding: 25px 100px;
      border-radius: 12px;
      background: #252525;
      margin-top: 20px;
      a{
        text-decoration: none;
        font-family: Anton;
        font-size: 38px;
        font-weight: 400;
        line-height: 24px;
        color: #fff;
      }
    }
    .social{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 70px;
      .item{
        margin-right: 32px;
        img{
          width: 60px;
          height: 60px;
          a{
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 100%;
            }
          }
        }
      }
    }
  }
}