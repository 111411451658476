.about{
  .banner{
    padding: .75rem 0;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../../assets/img/about_banner.png) no-repeat top center;
      background-size: cover;
      filter: grayscale(100%);
    }

    .title{
      position: relative;
      h1{
        font-family: Anton;
        font-size: .8rem;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        color: #252525;
        margin: 0;
        position: relative;
        text-transform: uppercase;
      }
      .svg{
        position: absolute;
        left: 50%;
        bottom: -60%;
        transform: translateX(-50%);
        svg{
          width: 2.76rem;
          height: .28rem;
        }
      }
    }
  }
  .explain{
    background: linear-gradient(180deg, #F4D250 -33.55%, #F4D250 100.74%);
    color: #252525;
    padding: .64rem 1.9rem;
    h2{
      text-align: center;
      font-family: Anton;
      font-size: .48rem;
      font-weight: 400;
      line-height: 1.4;
      margin: 0;
      text-transform: uppercase;
    }
    p{
      font-family: Avenir;
      font-size: .16rem;
      font-weight: 500;
      line-height: 1.4;
      margin-top: .4rem;
      span{
        font-weight: 900;
      }
    }
  }
  .offer{
    padding: .64rem 1.9rem;
    background-color: #fff;
    h2{
      font-family: Anton;
      font-size: 0.48rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      margin-bottom: .48rem;
      text-transform: uppercase;
    }
    .list{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: .24rem;
      .item{
        padding: .24rem;
        border-radius: .24rem;
        h3{
          font-family: Anton;
          font-size: .24rem;
          font-weight: 400;
          line-height: 1;
          text-align: left;
        }
        p{
          font-family: Avenir;
          font-size: .16rem;
          font-weight: 500;
          line-height: 1.4;
          text-align: left;
          margin-top: .12rem;
        }
        &:nth-of-type(odd){
          background-color: #252525;
          h3,p{
            color: #fff;
          }
        }
        &:nth-of-type(even){
          background-color: #F4D250;
        }
      }
    }
  }
  .picWall{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .item{
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .story{
    padding: .64rem 1.9rem;
    h2{
      font-family: Anton;
      font-size: .48rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
    }
    p{
      font-family: Avenir;
      font-size: .24rem;
      font-weight: 500;
      line-height: 1.25;
      text-align: left;
      margin-top: .4rem;
    }
    .des{
      font-family: Avenir;
      font-size: .24rem;
      font-weight: 900;
      line-height: 1.25;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin-top: .25rem;
      padding-left: .2rem;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 10%;
        width: 6px;
        height: 80%;
        background-color: #F4D250;
      }

    }

  }
  .meet{
    padding: 0 1.9rem;
    padding-bottom: 1.64rem;
    background: url(../../../assets/img/meet_bg.png) no-repeat bottom center;
    background-size: 100% auto;
    .content{
      background-color: #fff;
      border-radius: .24rem;
      padding: .56rem .8rem .6rem;
      h2{
        font-family: Anton;
        font-size: .48rem;
        font-weight: 400;
        line-height: 1;
        text-align: center;
      }
      .list{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: .72rem;
        .card-row {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        
        .center-row {
          justify-content: space-evenly; /* 使下方两个元素居中 */
        }
        
        .card-item{
          .avatar{
            width: 2rem;
            height: 2rem;
            border: 6px solid #F4D250;
            overflow: hidden; /* 确保图片不超出容器 */
            position: relative;
            border-radius: 5rem;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover; /* 确保图片充满容器，同时保持比例 */
              position: absolute;
              top: 0;
              left: 0;
            }
  
          }
          h3{
            font-family: Anton;
            font-size: .32rem;
            font-weight: 400;
            line-height: 1;
            text-align: center;
            text-transform: uppercase;
            margin-top: .16rem;
          }
        }
      }
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.8rem;
      // height: .72rem;
      padding: .24rem .64rem;
      background-color: #F4D250;
      border-radius: .16rem;
      box-shadow: .08rem .08rem 0px 0px #00FFFF;
      font-family: Anton;
      font-size: .24rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      color: #252525;
      text-decoration: none;
      margin: auto;
      margin-top: .45rem;
      

    }
  }
  &.mAbout{
    padding-top: 72px;
    .title {
      h1{
        font-size: 34px;
      }
      .svg{
        position: absolute;
        left: 50%;
        bottom: -50%;
        transform: translateX(-50%);
       
      }
    }
    .banner{
      height: 142px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .explain{
      padding: 40px 20px;
      h2{
        font-size: 28px;
      }
      p{
        font-size: 14px;
        margin-top: 24px;
      }
    }
    .offer{
      padding: 40px 20px;
      background-color: #EDEDED;

      h2{
        font-size: 28px;
        margin-bottom: 24px;
      }
      .list{
        grid-template-columns: 1fr;
        gap: 24px;
        .item{
          padding: 24px;
          h3{
            font-size: 20px;
            margin-bottom: 12px;
          }
          p{
            font-size: 14px;
          }
        }
      }
    }
    .picWall{
      .item{
        height: 199px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: auto;
          height: 100%;
        }
      }
    }
    .story{
      padding: 40px 20px;
      h2{
        font-size: 28px;
      }
      p{
        font-size: 14px;
        margin-top: 24px;
      }
      
    }
    .meet{
      padding: 0 20px 116px;
      background-size: 100% 684px;
      .content{
        padding: 40px 34px 20px;
        h2{
          font-size: 28px;
        }
        .list{
          .card-row{

            display: grid;
            grid-template-columns: repeat(2, 1fr); /* 第一排三列 */
            grid-template-rows: auto auto; /* 第一排和第二排 */
            gap: 45px;

            .card-item{
              width: 100%;
              .avatar{
                width: 4.2rem;
                height: 4.2rem;
              }
              h3{
                font-size: 24px;
                margin-top: 16px;
              }
            }
            .card-item:nth-child(3) {
              grid-column: span 2; /* 第二排跨三列 */
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            &:nth-child(2){
              margin-top: 45px;
            }
          }
        }
      }
      .btn{
        padding: 16px 80px;
        border-radius: 16px;
        box-sizing: border-box;
        font-size: 18px;
        margin-top: 40px;
        width: fit-content;
      }
    }
  }
}