iframe {
  display: none;
}

.Toastify{
  .Toastify__toast-container{
    z-index: 999999;
    font-size: .14rem;
  }
}

@font-face {
  font-family: Anton;
  src: url('./assets/font/Anton-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: Optif;
  src: url('./assets/font/OPTIFranklinGothic-Medium.otf') format("opentype");
  font-display: swap;
}
@font-face {
  font-family: AvenirLight;
  src: url('./assets/font/Avenir-Light-07.ttf');
  font-display: swap;
}

*{
  padding: 0;
  margin: 0;
}

html,
body {
  background-color: #EDEDED;
  margin: 0;
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  -webkit-touch-callout: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%!important;
  text-size-adjust: 100%!important;
  -moz-text-size-adjust: 100%!important;
}