.apply{
  .banner{
    padding: .75rem 0;
    background: url(../../../assets/img/apply_banner.png) no-repeat top center;
    background-size: cover;
    

    .title{
      position: relative;
      h1{
        font-family: Anton;
        font-size: .8rem;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        color: #252525;
        margin: 0;
        position: relative;
        text-transform: uppercase;
      }
      .svg{
        position: absolute;
        left: 50%;
        bottom: -60%;
        transform: translateX(-50%);
        svg{
          width: 2.76rem;
          height: .28rem;
        }
      }
    }
  }

  .question{
    padding: .64rem 1.9rem .96rem;
    background: url(../../../assets/img/question_bg.png) no-repeat;
    // background-size: auto 100% ;

    background-position: 0 -30%;
    
    .applylist{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: .24rem;
      .item{
        padding: .48rem .24rem;
        color: #fff;
        border-radius: .24rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        h2{
          font-family: Anton;
          font-size: .4rem;
          font-weight: 400;
          line-height: 1;
          text-align: center;
        }
        p{
          font-family: Avenir;
          font-size: .16rem;
          font-weight: 500;
          line-height: 1.25;
          text-align: center;
          margin: .42rem 0;
        }
        .btn{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.64rem;
          padding: .16rem .32rem;
          background-color: #F4D250;
          border-radius: .16rem;
          box-shadow: .08rem .08rem 0px 0px #00FFFF;
          font-family: Anton;
          font-size: .24rem;
          font-weight: 400;
          line-height: 1;
          text-align: center;
          color: #000;
          text-decoration: none;

        }
        &:nth-child(1){
          background: url(../../../assets/img/apply_bg1.png) no-repeat center center;
          background-size: cover;
        }
        &:nth-child(2){
          background: url(../../../assets/img/apply_bg2.png) no-repeat center center;
          background-size: cover;
        }
        &:nth-child(3){
          background: url(../../../assets/img/apply_bg3.png) no-repeat center center;
          background-size: cover;
        }
      }
    }
    .subtitle{
      font-family: Anton;
      font-size: .48rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      color: #FFFFFF;
      margin-top: 1.28rem;
      margin-bottom: .48rem;
    }
    .list{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: .12rem;
      
    }
    
  }

  .accordion-container {
    // display: flex;
    overflow: auto;
  }
  &.mApply{
    padding-top: 72px;
    .title {
      h1{
        font-size: 34px;
      }
      .svg{
        position: absolute;
        left: 50%;
        bottom: -50%;
        transform: translateX(-50%);
       
      }
    }
    .banner{
      height: 142px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .question{
      padding: 40px 20px 128px;
      background-size: 100% 1453px;
      background-position: bottom center;
      .applylist{
        grid-template-columns: 1fr;
        gap: 24px;
        .item{
          padding: 48px 24px;
          border-radius: 24px;
          h2{
            font-size: 40px;
          }
          p{
            font-size: 16px;
            margin: 32px 0;
          }
          .btn{
            padding: 16px 32px;
            border-radius: 16px;
            font-size: 24px;
            width: auto;
            box-shadow: 8px 8px 0px 0px #00FFFF;
          }
        }
      }
      .subtitle{
        font-size: 28px;
        margin-top: 80px;
        margin-bottom: 28px;
      }
      .list{
        grid-template-columns: 1fr;
        gap: 0;
        .custom-collapse{
          .custom-panel {
            margin-bottom: 12px;
            border-radius: 16px;
            .custom-panel-header{
              font-size: 18px;
              padding: 19px 16px;
              padding-right: 60px;
              .jiantou{
                width: 24px;
                height: 24px;
                right: 16px;
              }
            }
            .custom-panel-content{
              font-size: 14px;
              padding: 0 16px 12px;
            }
            &.active{
              .custom-panel-header{
                .jiantou {
                  transform: translateY(-50%) rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }

}


.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.grid-item {
  width: 100%;
}

.ant-collapse-item {
  margin-bottom: 16px;
}