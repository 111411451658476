
.header {
  width: 100vw;
  height: .8rem;
  // position: relative;
  // left: 0;
  // top: 0;
  padding: 0 .4rem;
  box-sizing: border-box;
  z-index: 10000;
  font-family: Optif;
  background: #252525;



  &-blur {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #252525;
    // -webkit-backdrop-filter: blur(10 / $base);
	  // backdrop-filter: blur(10 / $base);
    z-index: 10001;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10002;
  }

  &-logo {
    height: .4rem;
    a{
      display: flex;
      align-items: center;
      height: 100%;
      img {
        height: 100%;
      }
    }
  }

  &-nav {
    display: flex;
    align-items: center;
    
    &-item {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      margin-right: .64rem;
      &.active {
        .header-nav-item-title{
          color: #F4D250; /* Active link color */
        }
      }
      &.apply{
        padding: .08rem .24rem;
        background: #F4D250;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: .40rem;
        font-size: .2rem;
        font-weight: normal;
        text-transform: uppercase;
        box-sizing: border-box;
        .header-nav-item-title{
          color: #252525;

        }
      }

      // &:nth-last-child(1) {
      //   // margin-right: 0;
      // }

      // &:hover,&.active {
      //   .header-nav-item-title {

      //   }

      //   .header-nav-item-doll {
      //   }
      // }
      

      &-title {
      font-size: .2rem;
      color: #FFFFFF;
      }

      &-doll {
        width: 0vw;
        transition: all 0.15s;
        border-radius: 1px;
      }
    }
    .socialGroup{
      display: flex;
      align-items: center;
      justify-content: center;
      .item{
        display: flex;
        margin-left: .32rem;
        &:first-child{
          margin-left: 0;
        }
        img{
          width: .32rem;
        }
      }
    }
  }
}
