.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh; /* Full page height */
  height: .24rem;
  position: relative;
}

.cont{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 24px;
  height: 24px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #fff; /* Blue color */
  border-radius: 50%;
}
