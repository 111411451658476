
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    background: white;
    border-radius: 8px;
    padding: .32rem;
    width: 4.35rem;
    border: 8px solid #FFF7D7;
    max-width: 100%;
    box-shadow: 0px .04rem .48rem 0px #0000001A;
  
    position: relative;
  }


  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal-body {
    margin-top: 10px;
    .contion{
      h2{
        display: flex;
        align-items: center;
        justify-content: center;
        span{
          font-family: Avenir;
          font-size: .6rem;
          line-height: 1.3;
          margin-right: .07rem;
        }
        font-family: Anton;
        font-size: .48rem;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        margin-bottom: .21rem;
      }
      p{
        font-family: Avenir;
        font-size: .18rem;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
  
      }
    }
  }
  
  
  .modal-footer{
    display: flex;
    align-items: center;
    margin-top: .32rem;
    justify-content: center;
    .submit{
      width: auto;
      box-shadow: 8px 8px 0px 0px #F4D250;
      padding: .16rem .64rem;
      border-radius: .16rem;
      font-family: Anton;
      font-size: .24rem;
      font-weight: 400;
      line-height: 1;
      background: #252525;  
      color: #fff;      
      border: none;
    }
  }
  
  
  .error{
    &.modal-content{
      border: 8px solid #E0E0E0;
      .modal-footer{
        .submit{
          background: #EB79EB;
          box-shadow: 8px 8px 0px 0px #90FCF9;
          color: #252525;
        }
      }
    }
    
  }

  &.mModal{
    .modal-content{

      width: 80%;
      border-radius: 8px;
      padding: 32px;
      box-sizing: border-box;
      .modal-body{
        .contion{
          h2{
            font-size: 1.62rem;
            margin-bottom: .2rem;
            span{
              font-size:2rem;
            }
          }
          p{
            font-size: 18px;
          }
        }
      }
      .modal-footer{
        margin-top: 32px;
        .submit{
          box-shadow: 8px 8px 0px 0px #F4D250;
          padding: 16px 64px;
          border-radius: 16px;
          font-size: 24px;
        }
      }
    }
  }

}





