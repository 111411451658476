.footer{
  background: #252525;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 1.24rem 0;
  gap: 1.2rem;
  grid-template-columns: 1fr 1fr; 
  .logo{
    display: flex;
    justify-content: flex-end;
    a{
      display: flex;
      img{
        height: 1.43rem;
      }
    }
  }
  .nav{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .socialGroup{
      display: flex;
      align-items: center;
      margin-bottom: .28rem;
      .item{
        display: flex;
        margin-left: .32rem;
        a{
          display: flex;
        }
        &:first-child{
          margin-left: 0;
        }
        img{
          width: .32rem;
        }
      }
    }
    h4{
      margin: 0;
      font-family: Avenir;
      font-size: .16rem;
      font-weight: 900;
      line-height: 1.4;
      color: #FFFFFF;
    }
    p{
      font-family: Avenir;
      font-size: .12rem;
      font-weight: 400;
      line-height: 1.2;
      text-align: left;
      color: #FFFFFF;
      margin: 0;
    }
    .sever{
      font-family: Avenir;
      font-size: .12rem;
      font-weight: 400;
      line-height: 1.2;
      color: #FFFFFF;
      margin-top: 16px;
      a{
        font-weight: 900;
        color: #FFFFFF;
      }
    }
  }

  &.mfooter{
    padding: 36px 45px 45px;
    grid-template-columns: 1fr;
    gap: 38px;
    .logo{
      justify-content: center;
      a{
        img{
          height: 90px;
        }
      }
    }
    .nav{
      justify-content: center;
      align-items: center;
      .socialGroup{
        .item{
          margin-left: 40px;
          &:first-child{
            margin: 0;
          }
          a{
            img{
              width: 40px;
            }
          }
        }
      }
      h4{
        margin-top: 10px;
        font-size: 12px;
      }
      p{
        font-size: .3rem;
      }
      .sever{
        font-size: 12px;
      }
    }

  }
}