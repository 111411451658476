.home{
  .title{
    position: relative;
    margin-top: .8rem;
    margin-bottom: .4rem;
    h1{
      font-family: Anton;
      font-size: .54rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      color: #252525;
      margin: 0;
      position: relative;
      text-transform: uppercase;
    }
    .svg{
      position: absolute;
      left: 50%;
      bottom: -90%;
      transform: translateX(-50%);
      svg{
        width: 2.76rem;
        height: .28rem;
      }
    }
  }
  .banner{
    height: 6.83rem;
    background: url(../../../assets/img/banner.png) no-repeat top center ;
    background-size: cover;
    background-color: #F4D250;
    position: relative;
    .bg{
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      .background-video {
        position: absolute;
        top: 50%;
        left: 50%;
        // min-width: 100%;
        // min-height: 100%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
        // z-index: -1;
      }
    }
    h1{
      margin: 0;
      color: #F4D250;
      font-family: Anton;
      font-size: 1.2rem;
      position: absolute;
      top: 2.6rem;
      left: 1.9rem;
      line-height: 1.1;
      text-transform: uppercase;
    }
    p{
      font-family: Anton;
      font-size: .32rem;
      font-weight: 400;
      line-height: 1.2;
      text-decoration-skip-ink: none;
      position: absolute;
      top: 4rem;
      left: 1.9rem;
      color: #FF00FF;
    }
    .tiktoklive{
      position: absolute;
      bottom: 1.05rem;
      left: 1.9rem;
      height: 1.4rem;
    }

  }
  .Live{
    padding: 0 1.90rem;
    p{
      font-family: Avenir;
      font-size: .24rem;
      font-weight: 400;
      text-align: center;
      display: block;
      span{
        font-weight: 800;
      }

    }
    .list{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;  /* 定义三列，每列宽度相等 */
      gap: .24rem;  
      margin-top: .48rem;
      padding-bottom: .8rem;
      .item{
        border-radius: .26rem;
        border: 8px solid #F4D250;
        padding: .16rem .1rem;
        background-color: #fff;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        &:nth-child(3){
          h2{
            span{
              font-size: .8rem;
            }
          }
        }
        h2{
          font-family: Anton;
          font-size: .80rem;
          font-weight: 400;
          line-height: 1;
          text-align: center;
          margin: 0;
          span{
            font-family: Anton;
            font-size: .40rem;
            font-weight: 400;
            line-height: 1;
          }
        }
        p{
          margin-bottom: 0;
          font-family: Avenir;
          font-size: .16rem;
          font-weight: 400;
          line-height: 1.2;
          text-align: center;

        }
        &:last-child{
          background-color: #F4D250;
          h3{
            margin: 0;
            width: 100%;
            p{
              font-family: Anton;
              font-weight: 400;
              text-align: center;
              line-height: 1;
              font-size: .24rem;
              margin-bottom: .1rem;
              &:nth-child(2){
                text-align: right;
                font-size: .16rem;
              }
              &:last-child{
                font-size: .18rem;
              }

            }
          }
          p{
            margin: 0;

          }
        }
      }    
    }
  }
  .Creator{
    padding: .3rem 1.9rem .8rem;
    position: relative;
    background: url(../../../assets/img/Creator_bg.png) no-repeat bottom center ;
    background-size: 100% auto;
    .title{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
    }
    .mySwiper{
      width: 100%;
      height: 5.84rem;
      background: #fff;
      border-radius: .24rem;
      .item{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        padding: .48rem 1.2rem;
        padding-bottom: 1.26rem;
        box-sizing: border-box;
        .avator{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          img{
            width: .68rem;
            height: .68rem;
            border: .04rem 0px 0px 0px;
          }
          h3{
            margin: 0;
            font-family: Avenir;
            font-size: .24rem;
            font-weight: 500;
            line-height: 1.2;
            text-align: center;
            margin-top: .07rem;
          }
        }
        p{
          font-family: Avenir;
          font-size: .32rem;
          font-weight: 900;
          line-height: 1.4;
          text-align: center;
          margin-top: .16rem;
          margin-bottom: .48rem;
        }
       
      }
      .swiper-button-prev {
        background-image: url('../../../assets/img/arrow.png');  /* 替换为上一张图片的路径 */
        background-size: cover;  /* 使图片铺满按钮 */
        background-repeat: no-repeat;  /* 防止图片重复 */
        width: 30px;  /* 调整按钮宽度以适应图片 */
        height: 30px;  /* 调整按钮高度以适应图片 */
        &::after{
          content: '';
        }
      }
      
      .swiper-button-next {
        background-image: url('../../../assets/img/arrow.png');  /* 替换为下一张图片的路径 */
        background-size: cover;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        transform: rotate(180deg);
        &::after{
          content: '';
        }
      }
    }
    .btn{
      position: absolute;
      bottom: 1.28rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.8rem;
      // height: .72rem;
      padding: .24rem .64rem;
      background-color: #252525;
      border-radius: .16rem;
      box-shadow: .08rem .08rem 0px 0px #F4D250;
      font-family: Anton;
      font-size: .24rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      color: #fff;
      text-decoration: none;
      z-index: 9;

    }
  }
  .team{
    background: 
    url(../../../assets/img/our_bg.png) ,linear-gradient(180deg, #F4D250 -34.21%, #F4D250 102.75%) no-repeat center center;
  background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 1.9rem 1.62rem;
    position: relative;
    .list{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: .65rem;
      .item{
        width: 1.4rem;
        display: flex;
      }
    }
    .join{
      padding: 1.08rem 1.45rem;
      background: url(../../../assets/img/join_bg.png) , #252525 no-repeat center center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: .48rem;
      border-radius: .3rem;
      p{
        font-family: Avenir;
        font-size: .24rem;
        font-weight: 800;
        line-height: 1.2;
        text-align: center;
        color: #fff;
      }
      .btn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.8rem;
        // height: .72rem;
        padding: .24rem .64rem;
        background-color: #F4D250;
        border-radius: .16rem;
        box-shadow: .08rem .08rem 0px 0px #00FFFF;
        font-family: Anton;
        font-size: .24rem;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        color: #252525;
        text-decoration: none;
        margin-top: 42px;
      }
    }
  }


  &.mHome{
    .title {
      margin-top: 60px;
      margin-bottom: 16px;
      h1{
        font-size: 34px;
      }
      .svg{
        position: absolute;
        left: 50%;
        bottom: -50%;
        transform: translateX(-50%);
       
      }
    }
    .banner{
      height: 689px;
      height: 689px;
      background-position: -2px 0;
      background-size: 1003px 683px; 
      .bg .background-video{
        width: auto;
        height: 100%;
      } 
      h1{
        font-size: 80px;
        top: 306px;
        left: 20px;
      }
      .tiktoklive{
        height: 60px;
        bottom: 101px;
      }
      p{
        font-size: 20px;
        top: 485px;
        left: 20px;
      }
    }
    .Live{
      padding: 0 20px;
      p{
        font-size: 18px;
        padding: 10px;
      }
      .list{
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 第一排三列 */
        grid-template-rows: auto auto; /* 第一排和第二排 */
        gap: 10px; /* 设置网格间距 */
        .item{
          border: 3.36px solid #F4D250;
          padding: 13px 10px;
          border-radius: 16px;
          h2{
            font-size: 30px;
            span{
              font-size: 14px;
            }
          }
          p{
            font-size: 16px;
          }
        }
        .item:nth-child(4) {
          grid-column: span 3; /* 第二排跨三列 */
          padding: 30px 40px;
          h3{
            p{
              text-align: left;
              font-size: 18px;
              &:nth-child(1){
                font-size: 24px;
              }
              &:nth-child(2){
                font-size: 16px;
                text-align: right;
              }
            }
          }
          p{
            font-size: 16px;
          }
        }
      }
    }
    .Creator{
      padding: 44px 20px 60px;
      background-size: 100% 268px;
      .btn{
        width: max-content;
        padding: 16px 80px;
        border-radius: 16px;
        box-sizing: border-box;
        font-size: 18px;
        box-shadow: 4px 4px 0px 0px #F4D250;
        bottom: 80px;
      }
      .title{
        margin-top: 75px;
        width: 100%;
      }
       .mySwiper {
         height: auto;
         border-radius: 16px;
        .item {
          padding: 102px 30px 86px;
          .avator{
            img{
              width: 48px;
              height: 48px;
            }
            h3{
              font-size: 16px;
            }
          }
          p{
            font-size: 18px;
          }
          
        }
        .swiper-button-prev, .swiper-button-next{
          top: 140px;
        }
        .swiper-button-prev{
          left: 20px;
        }
        .swiper-button-next{
          right: 20px;
        }
       }
    }
    .team{
      padding: 60px 20px;
      padding-top: 0;
      .join{
        padding: 50px;
        margin-top: 30px;
        p{
          font-size: 18px;
        }
        .btn{
          width: 100%;
          padding: 16px 0px;
          border-radius: 16px;
          box-sizing: border-box;
          font-size: 18px;
          margin-top: 40px;
        }
      }
    }
  }
}

@keyframes dashoffsetAnimation {
  from {
    stroke-dashoffset: 3094;
  }
  to {
    stroke-dashoffset: 0;
  }
}

#myPath {
  animation: dashoffsetAnimation 5s linear infinite;
}






/* 当屏幕宽度小于 768px 时应用以下样式 */
@media (max-width: 767px) {
 
}