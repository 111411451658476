// .custom-collapse-container {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: flex-start; /* 子元素靠顶部对齐 */
// }

.custom-collapse{
  .custom-panel{
    // padding: .16rem .24rem;
    gap: 0px;
    border-radius: .16rem;
    background: #FFFFFF0D;
    margin-bottom: .12rem;


    &-header{
      font-family: Avenir;
      font-size: .2rem;
      font-weight: 900;
      line-height: 1.4;
      text-align: left;
      color: #fff;
      padding: .16rem .24rem;
      transition: all 0.2s linear;
      position: relative;
      .jiantou{
        width: .24rem;
        height: .24rem;
        position: absolute;
        top: 50%;
        right: .24rem;
        transform: translateY(-50%);
        transition: all 0.2s linear;
      }
    }
    &.active{
      .custom-panel-header{
        padding-bottom: 0;
        .jiantou{
          transform: translateY(0) rotate(180deg);
        }
      }
      .custom-panel-content{

        display: block;
      }
    }

    .custom-panel-content {
      font-family: Avenir;
      font-size: .16rem;
      font-weight: 500;
      line-height: 1.25;
      text-align: left;
      color: #fff;
      margin-top: .08rem;
      transition: all 0.2s linear;
      padding: .16rem .24rem;
      padding-top: 0;
    
      display: none;
      /* 其他样式 */
    }
    
  }
}


